import styled from "styled-components/macro";

export const SectionLayoutStyles = styled.div`
	padding: 0 10px;
	padding-top: ${props => (props.greyed ? "40px" : "0")};
	position: relative;
	z-index: 3;
	/* z-index: ${props => (props.back ? "-1" : "auto")}; */
	${({ theme }) => theme.device.tablet} {
		padding: 0 10%;
	}
`;
