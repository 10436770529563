import styled from "styled-components/macro";

export const SectionSubtitle = styled.p`
	font-size: 28px;
	font-weight: 800;
	width: 95%;
	max-width: 280px;
	padding-bottom: ${props => (props.clean ? "1rem" : "2rem")};
	position: relative;
	&::after {
		width: 20px;
		height: 4px;
		display: ${props => (props.lined ? "block" : "none")};
		position: absolute;
		top: -10px;
		left: ${props => (props.lineLeft ? props.lineLeft : "20px")};
		background-color: ${({ theme }) => theme.colors.subColor};
		content: "";
	}
`;
