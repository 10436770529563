import styled, { css } from "styled-components/macro";

export const SectionOfferTextContainer = styled.div`
	background-color: ${({ theme, greyed }) => greyed && theme.colors.greyed};
	padding: 0rem 1rem;
	position: relative;
	margin-top: 1rem;
	// white box
	${({ greyed }) =>
		greyed &&
		css`
			padding: 2.5rem 1rem;
			position: relative;
			margin-top: 1.5rem;
			${({ theme }) => theme.device.mobileXl} {
				clip-path: ${({ withoutClip }) =>
					withoutClip ? "none" : "polygon(66% 0, 66% 26%, 100% 26%, 100% 100%, 0 100%, 0% 60%, 0 0)"};
			}
		`}
`;
