import React from "react";
import Section from "../Section";
import Circle from "../Circle";
import { SectionContainer, Description, SafetyCircle } from "./styles";

const SectionSafety = () => {
	const title = "bezpieczeństwo.";
	const subtitle = "Z nami bezpiecznie";
	const desc = `Jako Kancelaria podatkowa jesteśmy ubezpieczeni od odpowiedzialności cywilnej, dlatego
    bierzemy na siebie pełną odpowiedzialność związaną z księgowością twojej firmy.
    Zapewniamy poufność i bezpieczeństwo danych naszych klientów, ich kontrahentów oraz
    danych pracowniczych przede wszystkim poprzez świadczenie usług z poszanowaniem
    obowiązującego w Polsce prawa.
    Na bieżąco dostosowujemy się do wymagań stawianych przez ustawodawcę i rynek co
    pozwala nam na rekomendowanie i wdrożenie sprawdzonych i skutecznych rozwiązań w
    przedsiębiorstwach prowadzonych przez naszych klientów.
    W celu zabezpieczenia przetwarzanych przez nas danych osobowych wdrożyliśmy środki
    techniczne i organizacyjne adekwatne do kategorii danych i występujących zagrożeń.`;
	return (
		<div>
			<SectionContainer>
				<Section headerTitle={title} subtitle={subtitle} clean={true}>
					<Description>{desc}</Description>
				</Section>
				{/* <SafetyCircle /> */}
			</SectionContainer>
		</div>
	);
};

export default SectionSafety;
