import styled from "styled-components/macro";
import { CircleDiv } from "../Circle/styles";

export const Container = styled.div`
	padding: 4rem 0px 1.5rem 0px;
	padding-top: 250px;
	overflow: hidden;
	font-size: 14px;
	${({ theme }) => theme.device.tabletL} {
		padding-top: 500px;
	}
	${({ theme }) => theme.device.laptopM} {
		padding-top: 400px;
	}
`;

export const FooterCirlce = styled(CircleDiv)`
	top: -110px;
	left: -280px;
	border: 130px solid #ff9c41;
	z-index: -1;
	height: 260px;
	width: 260px;
	box-shadow: 5px 37px 70px rgb(13 0 0 / 25%) inset,
		0px 58px 60px -4px rgb(13 0 0 / 25%);
`;

export const Content = styled.div`
	position: relative;
	width: 90%;
	margin-left: auto;
	margin-right: auto;
`;

export const Company = styled.div`
	margin-top: 0.75rem;
	${({ theme }) => theme.device.mobileXl} {
		margin-top: auto;
		p {
			display: inline;
		}
	}
`;

export const Bolded = styled.a`
	font-weight: bold;
	text-decoration: none;
	color: inherit;
	cursor: pointer;
	&:hover {
		border-bottom: 2px solid black;
	}
`;
