import React from "react";
import { HeroTextContainer, HeroTextColored, HeroTextOrange } from "./styles";

const HeroText = () => {
	return (
		<HeroTextContainer>
			<HeroTextColored>
				<HeroTextOrange>20 lat doświadczenia </HeroTextOrange>
				<span>
					Jesteśmy Firmą w której pracują ludzie z pasją i zaangażowaniem.
					<HeroTextOrange> Gwarancja </HeroTextOrange>
					oraz <HeroTextOrange>pewność </HeroTextOrange> naszych usług
					<HeroTextOrange>.</HeroTextOrange>
				</span>
			</HeroTextColored>
		</HeroTextContainer>
	);
};

export default HeroText;
