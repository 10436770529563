import React from "react";
import {BgText, BgTextLeft} from './styles';

const BackgroundText = ({ text, left, top }) => {
	if (left) {
		return <BgTextLeft top={top}>{text}</BgTextLeft>;
	}
	return <BgText top={top}>{text}</BgText>;
};

export default BackgroundText;
