import styled from "styled-components/macro";

export const LogoContainer = styled.div`
	z-index: 2;
	padding: 12px 0 0 21px;
	position: relative;
	position: absolute;
	left: -80px;
	padding-left: 0;
	${({ theme }) => theme.device.tablet} {
		padding-top: 0;
		left: -55px;
		top: 0;
	}
	${({ theme }) => theme.device.tabletL} {
		padding-top: 0;
		left: -90px;
		top: 0;
	}
	&::after {
		content: "";
		display: block;
		width: 18px;
		height: 3px;
		background-color: ${({ theme }) => theme.colors.subColor};
		position: absolute;
		bottom: -8px;
		left: 18px;
	}
`;

export const LogoHeader = styled.h1`
	font-size: 16px;
	font-weight: 800;
	color: ${({ theme }) => theme.colors.greyedLight};
	line-height: 16px;
	${({ theme }) => theme.device.tablet} {
		font-size: 18px;
		line-height: 20px;
	}
	${({ theme }) => theme.device.tabletL} {
		font-size: 30px;
		line-height: 35px;
	}
	${({ theme }) => theme.device.laptopL} {
		font-size: 40px;
		line-height: 45px;
	}
	span {
		display: block;
		&:nth-child(3) {
			color: ${({ theme }) => theme.colors.main};
			font-weight: 400;
		}
	}
`;
