import styled from "styled-components/macro";

export const SectionTitle = styled.h3`
	font-size: 16px;
	color: ${({ theme }) => theme.colors.subColor};
	font-weight: bold;
	position: relative;
	display: ${({ withoutTitle }) => (withoutTitle ? "none" : "inline-block")};
	padding-left: 40px;
	margin-bottom: ${props => (props.clean ? "0.5rem" : "0")};
	&::after {
		content: "";
		height: ${({ horizontalLine }) => (horizontalLine ? "4px" : "20px")};
		width: ${({ horizontalLine }) => (horizontalLine ? "20px" : "4px")};
		position: absolute;
		display: block;
		top: ${({ lineTop }) => (lineTop ? lineTop : "50%")};
		transform: translateY(-50%);
		right: ${({ lineRight }) => (lineRight ? lineRight : "-15px")};
		background-color: ${({ theme }) => theme.colors.subColor};
	}
`;
