import React from "react";
import { Line, SwipeContainer, LineText } from "./styles";

const SwipeElem = () => {
	return (
		<SwipeContainer>
			<Line></Line>
			<LineText>przewiń.</LineText>
		</SwipeContainer>
	);
};

export default SwipeElem;
