import React from "react";
import { LogoContainer, LogoHeader } from "./styles";

const Logo = () => {
	return (
		<LogoContainer>
			<LogoHeader>
				<span>Kancelaria</span>
				<span>Prawno-Finansowa</span>
				<span>Beata Murszewska</span>
			</LogoHeader>
		</LogoContainer>
	);
};

export default Logo;
