import React from "react";
import { SectionContainer, Description, Container } from "./styles";
import Section from "../../../Section";
import BackgroundText from "../../../Background-Text";
import { v4 as uuid4 } from "uuid";

const AccountingServices = () => {
	const subtitle = "Usługi księgowe";

	const desc = [
		`Prowadzenie ksiąg rachunkowych`,
		`Prowadzenie podatkowej książki przychodów i rozchodów`,
		`Ewidencja ryczałtów`,
		`Rozliczenia VAT`,
		`Rozliczenia płacowe pracowników (listy płac, PIT-4R, PIT-40, PIT-11)`,
		`Rozliczenia z ZUS, PFRON, GUS`,
		`Sporządzanie sprawozdań finansowych`,
		`Bieżące doradztwo księgowe`,
		`Bieżący monitoring sytuacji ekonomicznej przedsiębiorstwa`,
		`Wsparcie administracyjne: przelewy, waystawianie faktur`,
	];

	const lineStyle = {
		horizontalLine: true,
		lineTop: "20px",
		lineRight: "-15px",
	};

	return (
		<Container>
			<SectionContainer>
				<Section subtitle={subtitle} {...lineStyle} clean={true}>
					<Description>
						<ul>
							{desc.map(elem => (
								<li key={uuid4()}>{elem}</li>
							))}
						</ul>
					</Description>
				</Section>
				<BackgroundText text="KANCELARIA" left={true} />
			</SectionContainer>
		</Container>
	);
};

export default AccountingServices;
