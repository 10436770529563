import styled from "styled-components/macro";
import Circle from "../Circle";
import { CircleDiv } from "../Circle/styles";

export const SpacerContainer = styled.div`
	/* width: 100%;
	position: relative;
	padding: 4rem 0;
	padding-bottom: 3rem;
	z-index: -2;
	clear: both; */
	width: calc(100% + 30px);
	position: relative;
	padding: 4rem 0;
	padding-bottom: 3rem;
	margin-left: auto;
	margin-right: auto;
	left: -20px;
	clear: both;
	transform: ${props => (props.rotate ? "rotate(7deg)" : "rotate(-7deg)")};
`;

export const SpacerCircle = styled(CircleDiv)`
	display: ${props => (props.visible ? props.visible : "none")};
	border: 30px solid #ff9c41;
	height: 60px;
	width: 60px;
	left: -10px;
	bottom: 0;
	right: auto;
	top: auto;
	${({ theme }) => theme.device.tabletL} {
		left: auto;
		right: -40px;
	}
`;

export const SpaceBar = styled.div`
	background-color: ${({ theme }) => theme.colors.lightOrange};
	height: 17px;
	/* width: 100%;
	transform: ${({ rotate }) => (rotate ? "rotate(-7deg)" : "rotate(7deg)")};
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	transform-origin: initial;
	position: absolute;
	left: -13px;
	width: 110vw; */
`;
