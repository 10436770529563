import styled from "styled-components/macro";

export const HeroTextContainer = styled.div`
	max-width: 245px;
	top: 50%;
	transform: translateY(-50%);
	left: -55px;
	position: absolute;
	z-index: 3;
	${({ theme }) => theme.device.tablet} {
		right: -55px;
		left: auto;
	}
	${({ theme }) => theme.device.tabletL} {
		max-width: 420px;
	}
	${({ theme }) => theme.device.laptopL} {
		max-width: 700px;
	}
	${({ theme }) => theme.device.desktop} {
		right: auto;
		left: calc(50% + 80px);
	}
`;

export const HeroTextColored = styled.h2`
	font-size: 18px;
	font-weight: 800;
	color: ${({ theme }) => theme.colors.main};
	z-index: 3;
	position: relative;
	${({ theme }) => theme.device.tabletL} {
		/* right: -55px;
		left: auto; */
		font-size: 30px;
	}
	${({ theme }) => theme.device.laptopL} {
		/* right: -55px;
		left: auto; */
		font-size: 44px;
	}
	&::after {
		content: "";
		display: block;
		width: 18px;
		height: 3px;
		background-color: ${({ theme }) => theme.colors.subColor};
		position: absolute;
		bottom: -8px;
		left: 55px;
	}
`;

export const HeroTextOrange = styled.span`
	/* &:first-child {
		display: block;
	} */
	color: ${({ theme }) => theme.colors.subColor};
`;
