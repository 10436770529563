import React, { useState, useEffect } from "react";
import {
	SectionContainer,
	Description,
	NewsList,
	Date,
	NewsHeader,
	NewsElem,
	PaginationList,
	NewsCircle,
	PaginationNumber,
} from "./styles";
import Section from "../Section";
import { ChevronLeft, ChevronRight } from "@styled-icons/boxicons-regular";
import BackgroundText from "../Background-Text";
import { useMediaQuery } from "react-responsive";
import Scroll from "react-scroll";
import { circleVariants } from "./variants";
import { v4 as uuid4 } from "uuid";

import { AnimatePresence } from "framer-motion";
const ScrollLink = Scroll.Link;

const SectionNews = () => {
	const [articles, setArticles] = useState([]);
	const title = "aktualności.";
	const subtitle = "Bądź na bieżąco";
	const desc = `Ponad dwudziestoletnie doświadczenie w obsłudze księgowej, kadrowej i podatkowej podmiotów gospodarczych
    Oferta dostosowana do potrzeb i oczekiwań klienta
    Nowoczesny obiekt biurowy wyposażony w najnowsze urządzenia techniczne
    Zapewnienie klientom spoza Gminy Czernikowo możliwości odbioru dokumentów z siedziby ich firmy
    Możliwość dostarczania dokumentów księgowych pocztą, kurierem
    Fachowa i kompetentna kadra pracownicza
    Konkurencyjne ceny dostosowane do zakresu świadczonych usług
    Terminowość zleconych zadań
    Zapewnienie obsługi prawnej we wszystkich dziedzinach prawa dzięki współpracy z wyspecjalizowanymi kancelariami prawniczymi`;

	useEffect(() => {
		async function fetchApi() {
			let response = await fetch(`${process.env.GATSBY_URI_PRODUCTION}`);
			response = await response.json();
			setArticles(response);
		}
		fetchApi();
	}, []);

	const [pages, setPages] = useState([1, 2, 3]);
	const [currentPage, setCurrentPage] = useState(1);
	const [articlesPerPage, setArticlesPerPage] = useState(3);
	const desktopSize = useMediaQuery({ query: "(min-device-width: 1000px)" });
	const reminder = Math.ceil(articles.length / 3);

	const handleClick = (event, arr) => {
		if (Number(event.target.id) === arr[2]) {
			higherPagination(pages);
		}
		if (Number(event.target.id) < arr[1] && arr[0] > 1) {
			lowerPagination(pages);
		}
		setCurrentPage(Number(event.target.id));
	};

	const indexOfLastArticle = currentPage * articlesPerPage;
	const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
	const currentArticles = articles.slice(indexOfFirstArticle, indexOfLastArticle);

	const renderArticles = currentArticles.map((article, index) => {
		return (
			<NewsElem initial={{ opacity: 0 }} key={uuid4()} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
				<Date>{article.date}</Date>
				<NewsHeader>{article.title}</NewsHeader>
				{article.content}
			</NewsElem>
		);
	});

	const higherPagination = arr => {
		setPages(
			arr.map(elem => {
				return elem + 1;
			})
		);
	};

	const lowerPagination = arr => {
		setPages(
			arr.map(elem => {
				return elem - 1;
			})
		);
	};

	const renderPageNumbers = pages.map(number => {
		if (number > reminder) {
			return null;
		}
		return (
			<ScrollLink to="aktualnosci" key={uuid4()} spy={true} delay={100} smooth={true} duration={400} offset={-50}>
				<PaginationNumber
					key={number}
					id={number}
					onClick={e => handleClick(e, pages)}
					active={currentPage === number ? true : false}
				>
					{number}
				</PaginationNumber>
			</ScrollLink>
		);
	});

	return (
		<div id="aktualnosci">
			<SectionContainer>
				<Section headerTitle={title} subtitle={subtitle} clean={true}>
					<Description>
						<NewsList>{renderArticles}</NewsList>
						<PaginationList>
							<p>
								<ChevronLeft size="40" />
							</p>
							<span>...</span>
							{renderPageNumbers}
							<span>...</span>
							<p>
								<ChevronRight size="40" />
							</p>
						</PaginationList>
					</Description>
				</Section>
				<NewsCircle variants={circleVariants} animate={desktopSize ? "animationOne" : "before"} />
				<BackgroundText text="PRAWNO_FINANSOWA" top="-450px" />
			</SectionContainer>
		</div>
	);
};

export default SectionNews;
