import styled from "styled-components/macro";

export const ImageHero = styled.img`
	opacity: 0.58;
	/* position: absolute; */
	right: 0;
	top: 0;
	width: 100%;
	height: 100%;
	${({ theme }) => theme.device.tablet} {
		object-fit: cover;
		/* height: 715px; */
		clip-path: polygon(
			0 26%,
			0 0,
			35% 0%,
			65% 0%,
			65% 31%,
			100% 31%,
			100% 50%,
			100% 100%,
			38% 100%,
			38% 65%,
			0 65%,
			0 48%
		);
	}
`;
