import React from "react";
import { SectionOfferTextContainer } from "./styles";
import SectionHeader from "../SectionHeader";
import SectionLayout from "../Section-Layout";
import SectionSubheader from "../SectionSubheader";
import { ThemeProvider } from "styled-components";
import { theme } from "../../utils/theme";

const Section = ({
	greyed = false,
	headerTitle,
	subtitle,
	lineTop,
	lineRight,
	horizontalLine,
	withoutTitle,
	back,
	clean,
	withoutClip,
	...props
}) => {
	return (
		<ThemeProvider theme={theme}>
			<SectionLayout back={back}>
				<SectionHeader
					title={headerTitle}
					lineTop={lineTop}
					lineRight={lineRight}
					horizontalLine={horizontalLine}
					withoutTitle={withoutTitle}
				/>
				<SectionOfferTextContainer greyed={greyed} withoutClip={withoutClip}>
					<SectionSubheader clean={clean}>{subtitle}</SectionSubheader>
					{props.children}
				</SectionOfferTextContainer>
			</SectionLayout>
		</ThemeProvider>
	);
};

export default Section;
