export const circleVariants = {
	animationOne: {
		x: [0, 150],
		y: [0, 100],
		transition: {
			x: {
				yoyo: Infinity,
				duration: 12,
			},
			y: {
				yoyo: Infinity,
				duration: 10,
			},
		},
	},
};
