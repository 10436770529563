import styled from "styled-components/macro";
import { SectionLayoutStyles } from "../Section-Layout/styles";
import { CircleDiv } from "../Circle/styles";

export const SectionContainer = styled.section`
	position: relative;
	margin-top: 2rem;
	${({ theme }) => theme.device.laptop} {
		margin-top: 5rem;
		${SectionLayoutStyles} {
			padding: 0 5rem;
		}
	}
	${({ theme }) => theme.device.laptopL} {
		${SectionLayoutStyles} {
			max-width: 1192px;
			margin-left: auto;
			margin-right: auto;
			padding-top: 2rem;
		}
	}
`;

export const NewsCircle = styled(CircleDiv)`
	top: -215px;
	left: auto;
	border: 30px solid #ff9c41;
	z-index: -1;
	height: 260px;
	width: 260px;
	box-shadow: 5px 37px 70px rgb(13 0 0 / 25%) inset, 0px 58px 60px -4px rgb(13 0 0 / 25%);
	${({ theme }) => theme.device.laptopL} {
		left: -10px;
		height: 400px;
		width: 400px;
		top: -32rem;
		border: 60px solid #ff9c41;
	}
`;

export const Description = styled.div`
	max-width: 95%;
	position: relative;
`;

export const NewsList = styled.ul`
	text-decoration: none;
	padding-left: 0;
`;

export const Date = styled.p`
	color: ${({ theme }) => theme.colors.lightestOrange};
	font-weight: bold;
	${({ theme }) => theme.device.laptopL} {
		z-index: -1;
		width: 110%;
		position: absolute;
		font-size: 170px;
		left: 3rem;
		top: -1.5rem;
		opacity: 0.25;
	}
`;

export const NewsHeader = styled.p`
	color: ${({ theme }) => theme.colors.main};
	font-weight: bold;
	padding-bottom: 2rem;
`;

export const NewsElem = styled.li`
	margin-bottom: 2rem;
	position: relative;
	${({ theme }) => theme.device.laptopL} {
		min-height: 175px;
	}
`;

export const PaginationList = styled.div`
	width: 80%;
	margin: 0 auto;
	display: flex;
	justify-content: space-around;
	align-items: center;
	font-weight: bold;
	max-width: 280px;
	/* span {
		cursor: pointer;
	} */
	${({ theme }) => theme.device.laptop} {
		margin: 0;
		margin-left: -20px;
	}
`;

export const PaginationNumber = styled.p`
	cursor: pointer;
	min-width: 1.5rem;
	text-align: center;
	position: relative;
	&::after {
		content: "";
		${({ active }) => (active ? `display:block;` : `display:none`)}
		position: absolute;

		width: 100%;
		height: 2px;
		background-color: ${({ theme }) => theme.colors.subColor};
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
	}
`;
