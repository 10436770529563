import React from "react";
import { SectionLayoutStyles } from "./styles";

const SectionLayout = props => {
	return (
		<SectionLayoutStyles greyed={props.greyed} back={props.back}>
			{props.children}
		</SectionLayoutStyles>
	);
};

export default SectionLayout;
