import { colors } from "./colors";
import { size } from "./sizes";

export const theme = {
	colors,
	line: {
		horizontal: {
			width: "20px",
			height: "4px",
		},
	},
	device: {
		mobileS: `@media (min-width: ${size.mobileS})`,
		mobileM: `@media (min-width: ${size.mobileM})`,
		mobileL: `@media (min-width: ${size.mobileL})`,
		mobileXl: `@media (min-width: ${size.mobileXl})`,
		tablet: ` @media (min-width: ${size.tablet})`,
		tabletM: ` @media (min-width: ${size.tabletM})`,
		tabletL: ` @media (min-width: ${size.tabletL})`,
		laptop: `@media (min-width: ${size.laptop})`,
		laptopM: `@media (min-width: ${size.laptopM})`,
		laptopL: `@media (min-width: ${size.laptopL})`,
		desktop: `@media (min-width: ${size.desktop})`,
	},
};
