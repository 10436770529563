import React, { useState, useEffect } from "react";
import "../../reset.scss";
import SectionHero from "../components/Section-Hero";
import Spacer from "../components/Spacer";
import { Helmet } from "react-helmet";
import { theme } from "../utils/theme";
import { ThemeProvider } from "styled-components";
import SectionServices from "../components/Section-Services";
import SectionSafety from "../components/Section-Safety";
import SectionNews from "../components/Section-News";
import Contact from "../components/Section-Contact";
import Footer from "../components/Footer";
import SectionOfferStrengths from "../components/Section-Offer-Strengths";
import Loader from "../components/Loader";
import { motion, AnimatePresence } from "framer-motion";
import { useMediaQuery } from "react-responsive";
import favicon from "../images/icon.png";

const loadVariant = {
	before: {
		opacity: 0,
	},
	after: {
		opacity: 1,
		transition: {
			delay: 0.5,
			stiffness: 0,
			duration: 0.5,
		},
	},
};

const Home = () => {
	const [loading, setLoading] = useState(true);
	const desktopSize = useMediaQuery({ query: "(min-device-width: 1000px)" });

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 1500);
	});
	return (
		<div>
			<Helmet htmlAttributes={{ lang: "pl-PL" }}>
				<meta charSet="utf-8" />
				<meta name="description" content="Kancelaria Prawno Finansowa Beata Murszewska" />
				<title>Kancelaria Prawno-Finansowa Beata Murszewska</title>
				<link rel="icon" type="img/png" href={favicon} />
			</Helmet>
			<>
				<ThemeProvider theme={theme}>
					<AnimatePresence>
						<motion.div variants={loadVariant} initial="before" animate="after" exit="before">
							<SectionHero />
							<SectionOfferStrengths />
							<Spacer rotate={true} />
							<SectionServices />
							<Spacer />
							<SectionSafety />
							<Spacer rotate={true} />
							<SectionNews />
							<Spacer rotate={false} visible />
							<Contact />
							<Footer />
						</motion.div>
					</AnimatePresence>
				</ThemeProvider>
			</>
		</div>
	);
};

export default Home;
