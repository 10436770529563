import React from "react";
import { SectionContainer, Descriptions, OfferCircle } from "./styles";
import Section from "../Section";
import Circle from "../Circle";
import { v4 as uuid4 } from "uuid";
import { useMediaQuery } from "react-responsive";
import { circleVariants } from "./variants";

const SectionOffer = () => {
	const desktopSize = useMediaQuery({ query: "(min-device-width: 1000px)" });
	const title = "oferta.";
	const subtitle = "Szeroki zakres usług";
	const desc = [
		`Kompleksowa obsługa księgowa-podatkowa i kadrowa firm`,
		`Pomoc w
    założeniu firmy`,
		`Przygotowanie i rozliczenie wniosków o
    dofinansowanie z Urzędu Pracy`,
		`Rozliczenie VAT w rolnictwie`,
		`Zeznania
    roczne PIT`,
		`Pełen zakres ubezpieczeń-komunikacyjne (OC, AC),
    majątkowe (mieszkania, domy, firmy), rolnicze (uprawy, budynki
    gospodarcze, maszyny i urządzenia), “na życie” (indywidualne i
    grupowe)`,
	];

	return (
		<div>
			<SectionContainer>
				<Section headerTitle={title} subtitle={subtitle} greyed={true}>
					<Descriptions>
						<ul>
							{desc.map(elem => (
								<li key={uuid4()}>{elem}</li>
							))}
						</ul>
					</Descriptions>
				</Section>
				<OfferCircle variants={circleVariants} animate={desktopSize ? "animationOne" : "before"} />
			</SectionContainer>
		</div>
	);
};

export default SectionOffer;
