import styled from "styled-components/macro";
import { SectionLayoutStyles } from "../../../Section-Layout/styles";
import { SectionOfferTextContainer } from "../../../Section/styles";
import { SectionSubtitle } from "../../../SectionSubheader/styles";
import { CircleDiv } from "../../../Circle/styles";

export const SectionContainer = styled.section`
	position: relative;
	margin-top: 2rem;

	${SectionOfferTextContainer} {
		clip-path: polygon(72% 0, 72% 15%, 100% 15%, 100% 100%, 0 100%, 0% 60%, 0 0);
		clip-path: none;
		${({ theme }) => theme.device.mobileXl} {
			clip-path: polygon(66% 0, 66% 18%, 100% 18%, 100% 100%, 0 100%, 0% 60%, 0 0);
		}
		${({ theme }) => theme.device.tabletM} {
			clip-path: polygon(66% 0, 66% 21%, 100% 21%, 100% 100%, 0 100%, 0% 60%, 0 0);
		}
	}
	${({ theme }) => theme.device.laptop} {
		${SectionLayoutStyles} {
			padding-left: 0;
			padding-right: 5rem;
		}
		${SectionOfferTextContainer} {
			clip-path: polygon(50% 0%, 100% 0, 100% 35%, 100% 100%, 79% 100%, 36% 100%, 36% 87%, 0 87%, 0% 35%, 0 0);

			padding-bottom: 8rem;
		}
	}
	${({ theme }) => theme.device.laptopL} {
		${SectionLayoutStyles} {
			max-width: 580px;
			padding: 0;
			margin-right: auto;
			margin-left: 0;
		}
	}
`;

export const ServiceCircle = styled(CircleDiv)`
	top: 50%;
	left: auto;
	border: 60px solid #ff9c41;
	z-index: -2;
	height: 160px;
	width: 160px;
	right: -130px;
	box-shadow: 5px 37px 70px rgb(13 0 0 / 25%) inset, 0px 58px 60px -4px rgb(13 0 0 / 25%);
	${({ theme }) => theme.device.tabletL} {
		top: -100px;
	}
`;

export const Description = styled.div`
	max-width: 95%;
	ul {
		list-style-type: disc;
	}
`;

export const Container = styled.div`
	${({ theme }) => theme.device.laptop} {
		float: right;
		width: 50%;
		${SectionSubtitle} {
			padding-bottom: 0;
		}
	}
`;
