import styled from "styled-components/macro";
import Circle from "../Circle";
import { CircleDiv } from "../Circle/styles";
import { motion } from "framer-motion";
import { useMediaQuery } from "react-responsive";

export const LayoutContent = styled.div`
	position: relative;
	min-height: 470px;
	overflow: hidden;
	overflow: visible;
	width: 75%;
	margin-left: auto;
	max-height: 905px;
	${({ theme }) => theme.device.mobileL} {
		height: 100vw;
		width: 75vw;
	}

	${({ theme }) => theme.device.tablet} {
		margin-left: auto;
		margin-right: auto;
		margin-top: 3rem;
	}
	// &::after {
	// 	content: "";
	// 	display: block;
	// 	width: 60px;
	// 	height: 60px;
	// 	position: absolute;
	// 	top: 0;
	// 	right: 0;
	// 	background-color: #fff;
	// }
`;

export const LogoNav = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const HeroImageContainer = styled.div`
	height: 100%;
	max-width: 715px;
	position: absolute;
	${({ theme }) => theme.device.mobileL} {
		position: static;
	}
	${({ theme }) => theme.device.tablet} {
		clip-path: polygon(
			0 26%,
			0 0,
			35% 0%,
			65% 0%,
			65% 31%,
			100% 31%,
			100% 50%,
			100% 100%,
			38% 100%,
			38% 65%,
			0 65%,
			0 48%
		);
	}
	${({ theme }) => theme.device.desktop} {
		margin-left: auto;
		margin-right: auto;
	}
`;

export const HeroCircle = styled(CircleDiv)`
	top: auto;
	border: 40px solid #ff9c41;
	z-index: -1;
	height: 80px;
	width: 80px;
	bottom: -75px;
	right: -80px;
	display: none;
	${({ theme }) => theme.device.mobileL} {
		display: block;
	}
	${({ theme }) => theme.device.tablet} {
		left: -75px;
		top: 53%;
		right: auto;
		border: 50px solid #ff9c41;
		height: 100px;
		width: 100px;
		box-shadow: none;
	}
	${({ theme }) => theme.device.tabletM} {
		top: 51%;
	}
	${({ theme }) => theme.device.desktop} {
		left: calc(51% - 340px);
		transform: translateX(-50%);
	}
`;

export const HeroCircleTop = styled(CircleDiv)`
	display: none;
	border: 40px solid #ff9c41;
	z-index: -1;
	height: 100px;
	width: 100px;
	${({ theme }) => theme.device.tabletL} {
		border: 80px solid #ff9c41;
		z-index: -1;
		height: 170px;
		width: 170px;
		display: block;
		top: -100px;
		right: -125px;
	}
`;

export const HeroSectionContainer = styled(motion.div)`
	overflow: hidden;
	position: relative;
`;
