export const size = {
	mobileS: "320px",
	mobileM: "375px",
	mobileL: "414px",
	mobileXl: "500px",
	tablet: "650px",
	tabletM: "750px",
	tabletL: "1000px",
	laptop: "1024px",
	laptopM: "1150px",
	laptopL: "1440px",
	desktop: "2000px",
};
