import styled, { keyframes, css } from "styled-components/macro";
import { motion } from "framer-motion";

const slideOnHover = keyframes`
	0%{
		height:0%;
	}

	100%{
		height:100%;
	}
`;

export const NavContainer = styled(motion.div)`
	background-color: ${({ theme }) => theme.colors.white};
	${({ theme }) => theme.device.tablet} {
		border: 1px solid #ff9c41;
	}
	${({ theme }) => theme.device.tabletL} {
		border: none;
		right: -90px !important;
		width: auto !important;
		position: absolute !important;
		background-color: transparent;
	}
`;

export const List = styled(motion.ul)`
	padding-left: 0;
	${({ theme }) => theme.device.tabletL} {
		display: flex !important;
		justify-content: space-between;
		transform: none !important;
		padding-left: 0;
		height: auto;
		min-width: 300px;
	}
`;

export const ListItem = styled(motion.li)`
	text-align: center;
	padding-left: 3rem;
	padding: 0.5rem 0 0.5rem 3rem;
	padding-left: 0;
	a {
		font-weight: bold;
		font-size: 18px;
		cursor: pointer;
		position: relative;
		${({ theme }) => theme.device.tabletL} {
			&::after {
				content: "";
				width: 3px;
				height: 0;
				position: absolute;
				background-color: ${({ theme }) => theme.colors.subColor};
				left: -0.75rem;
			}
		}
	}
	a:hover::after {
		animation: ${slideOnHover} 300ms forwards;
	}
`;

export const Line = styled(motion.div)`
	width: 20px;
	height: 2px;
	background-color: ${({ theme }) => theme.colors.main};
	margin: 2.5px 0;
`;

export const NavBurger = styled.div`
	width: 60px;
	height: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-left: auto;
	${({ theme }) => theme.device.tabletL} {
		display: none;
	}
`;
