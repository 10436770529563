import styled, { keyframes } from "styled-components";
import { motion } from "framer-motion";

const slideIn = keyframes`
	0%{
		right:-20rem;
	}
	50%{
		right:0
	}

	100%{
		right:10rem;
	}
`;

export const SwipeContainer = styled.div`
	display: none;
	${({ theme }) => theme.device.tabletL} {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 50%;
		transform: rotate(-90deg);
		left: -200px;
	}
`;

export const Line = styled.span`
	width: 150px;
	height: 5px;
	overflow: hidden;
	position: relative;
	&::before {
		content: "";
		display: block;
		height: 5px;
		background-color: ${({ theme }) => theme.colors.subColor};
		position: absolute;
		top: 50%;
		width: 100%;
		transform: translateY(-50%);
		right: 0;
		animation: 3s ease 0s forwards infinite running ${slideIn};
	}
`;

// export const Line = styled(motion.span)`
// 	width: 140px;
// 	height: 5px;
// 	background-color: ${({ theme }) => theme.colors.subColor};
// `;

export const LineText = styled.p`
	font-weight: bold;
	font-size: 18px;
	padding-left: 0.8rem;
	line-height: 18px;
	position: relative;
	/* min-width: 240px; */
	text-align: right;
`;
