import styled from "styled-components/macro";
import { SectionLayoutStyles } from "../Section-Layout/styles";
import { CircleDiv } from "../Circle/styles";

export const SectionContainer = styled.section`
	position: relative;
	margin-top: 2rem;
	${({ theme }) => theme.device.laptop} {
		padding-left: 5rem;
		${SectionLayoutStyles} {
			padding: 0;
		}
	}
	${({ theme }) => theme.device.laptopL} {
		${SectionLayoutStyles} {
			max-width: 580px;
			margin-left: auto;
			margin-right: 1rem;
		}
	}
`;

export const OfferCircle = styled(CircleDiv)`
	top: 20px;
	left: -130px;
	border: 60px solid #ff9c41;
	z-index: -2;
	height: 160px;
	width: 160px;
	box-shadow: 5px 37px 70px rgb(13 0 0 / 25%) inset, 0px 58px 60px -4px rgb(13 0 0 / 25%);
	${({ theme }) => theme.device.tabletL} {
		height: 130px;
		width: 130px;
		left: -100px;
		border: 110px solid #ff9c41;
	}
`;

export const Descriptions = styled.div`
	max-width: 95%;
	ul {
		list-style-type: disc;
	}
`;
