import React from "react";
import { Container, Content, Company, Bolded, FooterCirlce } from "./styles";

const Footer = () => {
	const date = new Date().getFullYear();
	return (
		<Container>
			<Content>
				<p>© {date} Kancelaria Prawno-Finansowa - Beata Murszewska</p>
				<Company>
					<p>Strona wykonana przez: &nbsp;</p>
					<Bolded target="_blank" href="https://urzadzeniafiskalne24.pl/">
						UnikasDev &nbsp;
					</Bolded>
					<Bolded
						target="_blank"
						href="https://www.linkedin.com/in/rafal-wawrzyk/"
					>
						Rafał Wawrzyk
					</Bolded>
				</Company>
				<FooterCirlce />
			</Content>
		</Container>
	);
};

export default Footer;
