import styled from "styled-components/macro";

export const BgText = styled.p`
	position: absolute;
	font-size: 234px;
	font-weight: 800;
	z-index: 2;
	transform: rotate(-90deg);
	right: 25px;
	top: ${({ top }) => (top ? top : "-15px")};
	transform-origin: right;
	padding: 0;
	line-height: 234px;
	color: #f5f5f5;
`;

export const BgTextLeft = styled(BgText)`
	left: 25px;
	right: auto;
	transform: rotate(90deg);
	transform-origin: left;
	bottom: 0;
	top: auto;
`;
