import styled from "styled-components/macro";
import { SectionLayoutStyles } from "../Section-Layout/styles";

export const SectionContainer = styled.section`
	position: relative;
	margin-top: 2rem;
	${({ theme }) => theme.device.laptop} {
		padding-right: 5rem;
		${SectionLayoutStyles} {
			padding: 0;
			padding-top: 180px;
		}
	}
	${({ theme }) => theme.device.laptopL} {
		${SectionLayoutStyles} {
			max-width: 580px;
			margin-left: 1rem;
			margin-right: 0;
		}
	}
`;

export const Description = styled.div`
	max-width: 95%;
	ul {
		margin-top: 0;
		list-style-type: disc;
	}
`;
