import React from "react";
import { SectionContainer, Description } from "./styles";
import Section from "../../../Section";
import { v4 as uuid4 } from "uuid";

const AccountingServices = () => {
	const subtitle = "Usługi płacowe";

	const desc = [
		`Prowadzeniem ksiąg rachunkowych`,
		`Prowadzeniem podatkowej książki przychodów i rozchodów`,
		`Ewidencją ryczałtów`,
		`Rozliczeniami VAT`,
		`Rozliczeniami płacowymi pracowników (listy płac, PIT-4R, PIT-40, PIT-11)`,
		`Rozliczenia z ZUS, PFRON, GUS`,
		`Sporządzaniem sprawozdań finansowych`,
		`Bieżącego doradztwa księgowego`,
		`Bieżącego monitoringu sytuacji ekonomicznej przedsiębiorstwa`,
		`Wsparcie administracyjne: przelewy Wystawianie faktur`,
	];

	const lineStyle = {
		horizontalLine: true,
		lineTop: "20px",
		lineRight: "-15px",
	};

	return (
		<div>
			<SectionContainer>
				<Section subtitle={subtitle} {...lineStyle} clean={true}>
					<Description>
						<ul>
							{desc.map(elem => (
								<li key={uuid4()}>{elem}</li>
							))}
						</ul>
					</Description>
				</Section>
			</SectionContainer>
		</div>
	);
};

export default AccountingServices;
