import styled from "styled-components/macro";
import { SectionLayoutStyles } from "../../../Section-Layout/styles";
import { SectionOfferTextContainer } from "../../../Section/styles";

export const SectionContainer = styled.section`
	position: relative;
	margin-top: 2rem;
	${({ theme }) => theme.device.laptop} {
		${SectionLayoutStyles} {
			padding-left: 5rem;
			padding-right: 0;
		}
	}
	${({ theme }) => theme.device.laptopL} {
		${SectionLayoutStyles} {
			max-width: 580px;
			margin-left: auto;
			padding-left: 0;
		}
		${SectionOfferTextContainer} {
			padding-left: 0;
		}
	}
`;

export const Description = styled.div`
	max-width: 95%;
	ul {
		margin-top: 0;
		list-style-type: disc;
	}
`;

export const Container = styled.div`
	${({ theme }) => theme.device.laptop} {
		float: left;
		width: 50%;
	}
`;
