import React, { useState, useRef, useEffect } from "react";
import { NavContainer, Line, NavBurger, List, ListItem } from "./styles";
import Scroll from "react-scroll";
import { useMediaQuery } from "react-responsive";
import gsap from "gsap";
import { LineVariantTop, LineVariantBottom } from "./variants";
const ScrollLink = Scroll.Link;

const Nav = () => {
	const menuTimeline = useRef();
	const menuTimelineTablet = useRef();
	const wrapper = useRef(null);
	const [open, setOpen] = useState(false);
	const toggleMenu = () => {
		setOpen(!open);
	};
	const tabletSize = useMediaQuery({ query: "(min-device-width: 650px)" });
	const desktopSize = useMediaQuery({ query: "(min-device-width: 1000px)" });
	useEffect(() => {
		menuTimeline.current = gsap.timeline({
			defaults: { ease: "power3.inOut" },
			paused: true,
		});
		menuTimelineTablet.current = gsap.timeline({
			defaults: { ease: "power3.inOut" },
			paused: true,
		});
		const elements = wrapper.current.children;
		const navContainer = wrapper.current;
		const list = elements[1];

		menuTimeline.current
			.fromTo(
				navContainer,
				{
					zIndex: 5,
					position: "absolute",
					width: "60px",
					height: "60px",
					right: 0,
				},
				{ duration: 0.2, width: "300px", position: "fixed" }
			)
			.fromTo(
				navContainer,
				{},
				{
					duration: 0.2,
					height: "100vh",
					boxShadow:
						" 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
				}
			)
			.fromTo(
				list,
				{ width: 0, height: 0, x: "+=300" },
				{
					duration: 0.2,
					display: "block",
					width: "300px",
					height: "100vh",
					x: "0",
				}
			);

		menuTimelineTablet.current
			.fromTo(
				navContainer,
				{
					zIndex: 5,
					position: "absolute",
					width: "60px",
					height: "60px",
				},
				{ duration: 0.2, right: 0, width: "300px", position: "fixed", top: 0 }
			)
			.fromTo(navContainer, {}, { duration: 0.2, height: "100vh" })
			.fromTo(
				list,
				{ width: 0, height: 0, x: "+=300" },
				{
					duration: 0.2,
					display: "block",
					width: "300px",
					height: "100vh",
					x: "0",
				}
			);
		const onBodyClick = event => {
			if (wrapper.current && wrapper.current.contains(event.target)) {
				return;
			}
			setOpen(false);
		};
		document.body.addEventListener("click", onBodyClick);
		return () => {
			document.body.removeEventListener("click", onBodyClick);
		};
	}, []);

	useEffect(() => {
		if (tabletSize) {
			open
				? menuTimelineTablet.current.play()
				: menuTimelineTablet.current.reverse();
		} else {
			open ? menuTimeline.current.play() : menuTimeline.current.reverse();
		}
	}, [open]);

	desktopSize && open && setOpen(false);

	return (
		<NavContainer ref={wrapper}>
			<NavBurger onClick={() => toggleMenu()}>
				<Line variants={LineVariantTop} animate={open ? "after" : "before"} />
				<Line
					variants={LineVariantBottom}
					animate={open ? "after" : "before"}
				/>
			</NavBurger>
			<List>
				<ListItem>
					<ScrollLink
						onClick={() => setOpen(false)}
						to="uslugi"
						spy={true}
						delay={desktopSize ? 100 : 601}
						smooth={true}
						duration={400}
						offset={-50}
					>
						usługi.
					</ScrollLink>
				</ListItem>
				<ListItem>
					<ScrollLink
						onClick={() => setOpen(false)}
						to="aktualnosci"
						spy={true}
						delay={desktopSize ? 100 : 601}
						smooth={true}
						duration={800}
						offset={-50}
					>
						aktualności.
					</ScrollLink>
				</ListItem>
				<ListItem>
					<ScrollLink
						onClick={() => setOpen(false)}
						to="kontakt"
						spy={true}
						delay={desktopSize ? 100 : 601}
						smooth={true}
						duration={800}
						offset={-50}
					>
						kontakt.
					</ScrollLink>
				</ListItem>
			</List>
		</NavContainer>
	);
};

export default Nav;
