import React, { useEffect } from "react";
import { TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { StyledMap } from "./styles";

const MapLeaflet = () => {
	const defaultPosition = [52.94409106675947, 18.929133979761982];
	useEffect(() => {
		let DefaultIcon = L.icon({
			iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-grey.png",
			shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
			iconSize: [25, 41],
			iconAnchor: [12, 41],
			popupAnchor: [1, -34],
			shadowSize: [41, 41],
		});

		L.Marker.prototype.options.icon = DefaultIcon;
	}, []);

	if (typeof window !== "undefined") {
		return (
			<StyledMap center={defaultPosition} zoom={16}>
				<TileLayer
					attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
					url="http://{s}.tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
				/>
				<Marker position={defaultPosition}>
					<Popup>
						<a target="_blank" href="https://goo.gl/maps/CncHGfuBDJaQ7sRX8">
							Wyznacz trasę do kancelarii
						</a>
					</Popup>
				</Marker>
			</StyledMap>
		);
	}

	return null;
};

export default MapLeaflet;
