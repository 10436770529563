import React from "react";
import {
	SectionContainer,
	Description,
	Container,
	ServiceCircle,
} from "./styles";
import Section from "../../../Section";
import Circle from "../../../Circle";
import { v4 as uuid4 } from "uuid";
import { useMediaQuery } from "react-responsive";
import { circleVariants } from "./variants";

const HrServices = () => {
	const desktopSize = useMediaQuery({ query: "(min-device-width: 1000px)" });
	const subtitle = "Usługi kadrowe";

	const desc = [
		`Prowadzenie akt osobowych, sporządzanie dokumentacji związanej z nawiązywaniem
		i rozwiązywaniem stosunku pracy. Kontrolowanie terminów umów o pracę.`,
		`Obsługa zatrudnienia osób w ramach umów cywilnoprawnych`,
		`Prowadzenie spraw związanych z urlopami w tym m.in. ustalanie uprawnień do
		urlopu, prowadzenie ewidencji urlopów wypoczynkowych`,
		`Monitorowanie kończących się terminów badań lekarskich oraz szkoleń BHP`,
		`Wprowadzanie/aktualizacja danych na potrzeby ZUS`,
		`Rejestracja na potrzeby ZUS pracowników/pracodawców`,
		`Sporządzanie obowiązującej sprawozdawczości (m.in. PFRON)`,
		`Pomoc podczas kontroli z Państwowej Inspekcji Pracy i ZUS. Dostarczanie
		urzędnikom niezbędnej dokumentacji oraz wypełnianie spraw pokontrolnych`,
	];

	const lineStyle = {
		horizontalLine: true,
		lineTop: "20px",
		lineRight: "-15px",
		withoutTitle: true,
	};

	return (
		<Container>
			<SectionContainer>
				<Section subtitle={subtitle} greyed {...lineStyle}>
					<Description>
						<ul>
							{desc.map(elem => (
								<li key={uuid4()}>{elem}</li>
							))}
						</ul>
					</Description>
				</Section>
				<ServiceCircle
					variants={circleVariants}
					animate={desktopSize ? "animationOne" : "before"}
				/>
			</SectionContainer>
		</Container>
	);
};

export default HrServices;
