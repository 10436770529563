import styled from "styled-components/macro";
import { SectionLayoutStyles } from "../Section-Layout/styles";

export const SectionContainer = styled.section`
	position: relative;
	margin-top: 2rem;
	${({ theme }) => theme.device.laptop} {
		${SectionLayoutStyles} {
			padding: 0 5rem;
		}
	}
	/* ${({ theme }) => theme.device.laptop} {
		margin-top: 5rem;
	} */
	${({ theme }) => theme.device.laptopL} {
		${SectionLayoutStyles} {
			max-width: 1192px;
			margin-left: auto;
			margin-right: auto;
			padding: 0;
		}
	}
`;

export const Description = styled.div`
	max-width: 95%;
`;

export const Paragraph = styled.p`
	padding-bottom: 1rem;
`;

export const List = styled.ul`
	margin-top: 1rem !important;
	display: block;
	list-style-type: disc !important;
	margin-block-start: 1em;
	margin-block-end: 1em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	padding-inline-start: 40px !important;
	list-style: auto;
`;
