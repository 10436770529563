import React from "react";
import { Container } from "./styles";
import SectionOffer from "../Section-Offer";
import SectionStrengths from "../Section-Strengths";

const SectionOfferStrengths = () => {
	return (
		<Container>
			<SectionOffer />
			<SectionStrengths />
		</Container>
	);
};

export default SectionOfferStrengths;
