import React from "react";
// import Image from "../../assets/pen.jpg";
import { ImageHero } from "./styles";
import { StaticImage } from "gatsby-plugin-image";

const HeroImage = () => {
	return <StaticImage alt="hero image" src="../../images/pen.jpg" />;
};

export default HeroImage;
