import React from "react";
import HeroImage from "../HeroImage";
import Nav from "../Nav";
import Logo from "../Logo";
import SwipeElem from "../Swipe-Elem";
import HeroText from "../HeroText";
import { useMediaQuery } from "react-responsive";
import { circleVariants } from "./variants";

import {
	LayoutContent,
	LogoNav,
	HeroImageContainer,
	HeroCircle,
	HeroSectionContainer,
	HeroCircleTop,
} from "./styles";

const SectionHero = () => {
	const desktopSize = useMediaQuery({ query: "(min-device-width: 1000px)" });

	return (
		<HeroSectionContainer>
			<LayoutContent>
				<LogoNav>
					<Logo />
					<Nav />
				</LogoNav>
				<HeroText />
				<HeroImageContainer>
					<HeroImage />
					<HeroCircle />
				</HeroImageContainer>
				<SwipeElem />
			</LayoutContent>
			<HeroCircleTop
				variants={circleVariants}
				animate={desktopSize ? "animationOne" : "none"}
			/>
		</HeroSectionContainer>
	);
};

export default SectionHero;
