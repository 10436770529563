import styled from "styled-components/macro";
import { motion } from "framer-motion";

export const CircleDiv = styled(motion.div)`
	border: ${props => (props.circleBorder ? props.circleBorder : props.theme.colors.lightOrange)};
	border-radius: 100%;
	height: 95px;
	width: 95px;
	background-color: transparent;
	box-sizing: content-box;
	position: absolute;
	right: -100px;
	bottom: -90px;
	top: auto;
	left: auto;
	z-index: auto;
	box-shadow: 5px 37px 70px rgb(13 0 0 / 25%) inset, 0px 58px 60px -4px rgb(13 0 0 / 25%);
`;
