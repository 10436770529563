import React from "react";
import { SectionContainer, Description } from "./styles";
import Section from "../Section";
import BackgroundText from "../Background-Text";
import { v4 as uuid4 } from "uuid";

const SectionStrengths = () => {
	const title = "atuty.";
	const subtitle = "Dlaczego my?";
	const desc = [
		`Ponad dwudziestoletnie doświadczenie w obsłudze księgowej, kadrowej i podatkowej podmiotów gospodarczych`,
		`Oferta dostosowana do potrzeb i oczekiwań klienta`,
		`Nowoczesny obiekt biurowy wyposażony w najnowsze urządzenia techniczne`,
		`Zapewnienie klientom spoza Gminy Czernikowo możliwości odbioru dokumentów z siedziby ich firmy`,
		`Możliwość dostarczania dokumentów księgowych pocztą, kurierem`,
		`Fachowa i kompetentna kadra pracownicza`,
		`Konkurencyjne ceny dostosowane do zakresu świadczonych usług`,
		`Terminowość zleconych zadań`,
		`Zapewnienie obsługi prawnej we wszystkich dziedzinach prawa dzięki współpracy z wyspecjalizowanymi kancelariami prawniczymi`,
	];
	return (
		<div>
			<SectionContainer>
				<Section headerTitle={title} subtitle={subtitle} clean={true}>
					<Description>
						<ul>
							{desc.map(elem => (
								<li key={uuid4()}>{elem}</li>
							))}
						</ul>
					</Description>
				</Section>
				<BackgroundText text="MURSZEWSKA" />
			</SectionContainer>
		</div>
	);
};

export default SectionStrengths;
