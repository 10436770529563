import React from "react";
import Section from "../Section";
import { SectionContainer, Descriptions, BoldText, Map } from "./styles";
import MapLeaflet from "../Map";

const Contact = () => {
	const title = "kontakt.";
	const subtitle = "Skontaktuj się z nami";
	return (
		<div id="kontakt">
			<SectionContainer>
				<Section
					headerTitle={title}
					subtitle={subtitle}
					greyed={true}
					back={true}
					withoutClip={true}
				>
					<Descriptions>
						<div>
							<p>
								<BoldText>Adres:</BoldText> Leśna 1A, 97-640 Czernikowo
							</p>
							<p>
								<BoldText>Telefon:</BoldText>: 54 288 92 22
							</p>
							<p>
								<BoldText>Email:</BoldText> kancelaria@kpfczernikowo
							</p>
							<p>
								<BoldText>Godziny:</BoldText>
								<br />
								Poniedziałek — Czwartek: 8:00–17:00
								<br />
								Piątek 8:00-15:00
							</p>
						</div>
					</Descriptions>
				</Section>
				<Map>
					<MapLeaflet />
				</Map>
			</SectionContainer>
		</div>
	);
};

export default Contact;
