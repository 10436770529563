import React from "react";
import { SectionTitle } from "./styles";

const SectionHeader = ({
	clean,
	title,
	horizontalLine,
	lineRight,
	lineTop,
	withoutTitle,
}) => {
	return (
		<SectionTitle
			clean={clean}
			horizontalLine={horizontalLine}
			lineRight={lineRight}
			lineTop={lineTop}
			withoutTitle={withoutTitle}
		>
			{title}
		</SectionTitle>
	);
};

export default SectionHeader;
