import React from "react";
import { SectionContainer, Description, Paragraph, List } from "./styles";
import Section from "../Section";
import AccountingServices from "./subsections/AccountingServices";
import HrServices from "./subsections/HrServices";
import PaymentServices from "./subsections/PaymentServices";
import { v4 as uuid4 } from "uuid";

const SectionServices = () => {
	const title = "usługi.";
	const subtitle = "Oferta na start";
	const desc = [
		`Jeżeli zamierzasz rozpocząć działalność gospodarczą lub zmienić biuro rachunkowe. Zgłoś się do nas!`,
		`Bezpłatnie pomożemy w załatwieniu wszelkich niezbędnych formalności koniecznych przy założeniu działalności gospodarczej oraz pomożemy w doborze najbardziej optymalnej formy opodatkowania
`,
		`Zaufało nam już wielu przedsiębiorców`,
		`O finanse każdego z klientów dbamy jak o nasze własne`,
		`Jeśli zdecydują się Państwo na współpracę z naszym biurem rachunkowym, Państwa finanse będą przez nas rozliczane z należytą skrupulatnością i profesjonalizmem`,
	];

	const list = [
		`prowadzeniem ksiąg rachunkowych`,
		`prowadzeniem podatkowej książki przychodów i rozchodów`,
		`ewidencją ryczałtów`,
		`rozliczeniami VAT`,
		`rozliczeniami płacowymi pracowników (listy płac, PIT-4R, PIT-40, PIT-11)`,
		`rozliczenia z ZUS, PFRON, GUS`,
	];
	return (
		<div id="uslugi">
			<SectionContainer>
				<Section headerTitle={title} subtitle={subtitle} clean={true}>
					<Description>
						{desc.map(elem => (
							<Paragraph key={uuid4()}>{elem}</Paragraph>
						))}
						<p>Zajmujemy się między innymi:</p>
						<List>
							{list.map(elem => (
								<li key={uuid4()}>{elem}</li>
							))}
						</List>
					</Description>
				</Section>
			</SectionContainer>
			<AccountingServices />
			<HrServices />
			<PaymentServices />
		</div>
	);
};

export default SectionServices;
