import React from "react";
import { SpacerContainer, SpaceBar, SpacerCircle } from "./styles";

const Spacer = ({ rotate = false, visible = false }) => {
	return (
		<SpacerContainer rotate={rotate}>
			<SpaceBar />
			<SpacerCircle visible={visible} />
		</SpacerContainer>
	);
};

export default Spacer;
