import styled from "styled-components/macro";
import { CircleDiv } from "../Circle/styles";
import { motion } from "framer-motion";

export const LoaderCircle = styled(CircleDiv)`
	border: 40px solid #ff9c41;
	z-index: 1;
	height: 100px;
	width: 100px;
	top: 50%;
	left: 50%;
`;

export const LoaderContainer = styled(motion.div)`
	width: 100vw;
	height: 100vh;
	left: 100vw;
	position: relative;
	overflow: hidden;
	background-color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	z-index: 1000;
`;

export const LoaderList = styled.ul`
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.5rem;
	color: #ff9c41;
	padding-bottom: 5rem;
	${({ theme }) => theme.device.tabletL} {
		font-size: 3rem;
		padding-bottom: 0;
	}

	li {
		padding: 0.5rem;
		display: flex;
		justify-content: center;
		align-items: center;
		filter: blur(0);
		user-select: none;
		${({ theme }) => theme.device.tabletL} {
			padding: 1rem;
		}
	}
`;
