import styled from "styled-components/macro";
import MapImage from "../../images/map.png";
import { SectionSubtitle } from "../SectionSubheader/styles";
import { SectionOfferTextContainer } from "../Section/styles";
import { SectionLayoutStyles } from "../Section-Layout/styles";

export const SectionContainer = styled.section`
	position: relative;
	margin-top: 5rem;
	${({ theme }) => theme.device.mobileXl} {
		${SectionSubtitle} {
			text-align: center;
			max-width: 100%;
		}
	}
	${({ theme }) => theme.device.tabletL} {
		${SectionSubtitle} {
			text-align: left;
			max-width: 350px;
		}
		${SectionOfferTextContainer} {
			width: 60%;
		}
	}
	${({ theme }) => theme.device.laptop} {
		${SectionLayoutStyles} {
			max-width: 1192px;
			padding: 0 5rem;
		}
	}
	${({ theme }) => theme.device.laptopL} {
		${SectionLayoutStyles} {
			max-width: 1192px;
			padding: 0 5rem;
			margin-left: auto;
			margin-right: auto;
		}
	}
`;

export const Descriptions = styled.div`
	max-width: 95%;
	padding-bottom: 7rem;
	${({ theme }) => theme.device.mobileXl} {
		p {
			text-align: center;
		}
	}
	${({ theme }) => theme.device.tabletL} {
		p {
			text-align: left;
		}
	}
`;

export const BoldText = styled.span`
	font-weight: bold;
`;

export const Map = styled.div`
	width: 90%;
	min-height: 260px;
	/* background-image: url(${MapImage}); */
	background-size: cover;
	background-position: center;
	position:relative;
	top: 85%;
	z-index: 1;
	margin-left: auto;
	margin-right: auto;
	margin-top: -80px;
	box-shadow: 0px 58px 60px -4px rgba(0, 0, 0, 0.25);
	z-index:4;
	${({ theme }) => theme.device.tablet} {
		width: 75%;
		min-height: 355px;
	}
	${({ theme }) => theme.device.tabletL} {
		width: 55%;
		min-height: 375px;
		position: absolute;
		right: 170px;
	}
	${({ theme }) => theme.device.laptopM} {
		width: 50%;
		min-height: 400px;
		position: absolute;
		right: 170px;
		top: 240px;
	}
	${({ theme }) => theme.device.laptopL} {
		min-height: 450px;
		left: 40%;
		max-width: 630px;
		right: auto;
	}
	${({ theme }) => theme.device.desktop} {
		left: 42%;
	}
`;
