import styled from "styled-components/macro";
import { SectionLayoutStyles } from "../Section-Layout/styles";
import { CircleDiv } from "../Circle/styles";
import Circle from "../Circle";

export const SectionContainer = styled.section`
	position: relative;
	margin-top: 2rem;
	${({ theme }) => theme.device.laptop} {
		${SectionLayoutStyles} {
			padding-left: 13rem;
			padding-right: 5rem;
			padding: 3rem 5rem 3rem 13rem;
		}
	}
	${({ theme }) => theme.device.laptopL} {
		${SectionLayoutStyles} {
			max-width: 1000px;
			margin-left: auto;
			margin-right: auto;
			padding: 6rem 0 6rem 16rem;
		}
	}
`;

export const SafetyCircle = styled(CircleDiv)`
	top: 20px;
	left: -130px;
	z-index: -1;
	border: 60px solid #ff9c41;
	height: 160px;
	width: 160px;
	box-shadow: 5px 37px 70px rgb(13 0 0 / 25%) inset,
		0px 58px 60px -4px rgb(13 0 0 / 25%);
`;

export const Description = styled.div`
	max-width: 95%;
`;
