import React from "react";
import { SectionSubtitle } from "./styles";

const SectionSubheader = props => {
	return (
		<SectionSubtitle
			clean={props.clean}
			lined={props.lined}
			lineLeft={props.lineLeft}
		>
			{props.children}
		</SectionSubtitle>
	);
};

export default SectionSubheader;
