export const LineVariantTop = {
	before: {
		rotate: 0,
	},
	after: {
		position: "absolute",
		rotate: 45,
	},
};

export const LineVariantBottom = {
	before: {
		rotate: 0,
	},
	after: {
		position: "absolute",
		rotate: -45,
		transition: {
			bounce: 0,
		},
	},
};
